import React from 'react'
import './phone.css'

const Phone = () => {
  return (
    <div className="phone-container">
      <img className="phone" src="image/phone.svg" alt="#" />
    </div>
  )
}

export default Phone
