import React from 'react'
import { motion } from 'framer-motion'
import './people.css'

const People = ({ isOpen }) => {
  const Variant1 = {
    open: {
      d:
        'M62.3904 198.242C63.8611 198.264 65.3181 197.964 66.6589 197.361C68.0002 196.76 69.193 195.871 70.1534 194.758C71.1147 193.645 71.8187 192.335 72.2184 190.921C72.618 189.506 72.7024 188.021 72.4658 186.57L103.903 169.577L87.5375 160.8L60.1942 178.283C57.7831 178.841 55.6605 180.266 54.229 182.285C52.7977 184.304 52.1567 186.778 52.4271 189.239C52.6981 191.699 53.862 193.974 55.6986 195.634C57.5346 197.292 59.9156 198.221 62.3904 198.242Z',
    },
    closed: {
      d:
        'M108.725 203.938C110.062 203.325 111.248 202.427 112.199 201.306C113.151 200.186 113.845 198.87 114.233 197.452C114.622 196.034 114.694 194.549 114.446 193.1C114.198 191.651 113.635 190.274 112.797 189.066L133.862 160.199L115.313 159.319L98.1536 186.866C96.2176 188.408 94.9145 190.607 94.4913 193.046C94.068 195.484 94.5539 197.993 95.857 200.098C97.1602 202.202 99.1899 203.755 101.562 204.463C103.933 205.17 106.482 204.984 108.725 203.938Z',
    },
  }

  const Variant2 = {
    open: {
      d:
        'M79.698 188.447C78.7081 188.136 77.8706 187.462 77.3572 186.56L68.9537 171.781C68.4469 170.897 68.289 169.855 68.5119 168.86C68.7338 167.864 69.3207 166.989 70.1565 166.404L111.574 137.215L101.967 99.3397C101.463 97.3508 101.379 95.2787 101.719 93.2552C102.059 91.2316 102.817 89.3011 103.944 87.5863C105.071 85.8716 106.542 84.4102 108.265 83.2951C109.988 82.1805 111.924 81.4363 113.949 81.1102C115.975 80.7845 118.047 80.884 120.032 81.4023C122.018 81.9206 123.874 82.8468 125.482 84.1216C127.09 85.3963 128.414 86.9915 129.372 88.8065C130.329 90.6212 130.899 92.6153 131.044 94.6623L134.991 150.451C135.018 150.849 134.943 151.246 134.771 151.604C134.6 151.963 134.338 152.272 134.013 152.5L83.2673 187.896C82.7993 188.222 82.2681 188.447 81.7084 188.556C81.1491 188.665 80.5733 188.658 80.0163 188.532C79.9089 188.508 79.8029 188.48 79.698 188.447Z',
    },
    closed: {
      d:
        'M120.133 187.648C119.106 187.794 118.06 187.546 117.208 186.952L103.263 177.228C102.425 176.648 101.834 175.775 101.607 174.781C101.379 173.787 101.532 172.744 102.035 171.857L126.861 127.686L101.891 97.6303C100.58 96.0519 99.612 94.2179 99.0483 92.2449C98.4846 90.2719 98.3378 88.2032 98.6172 86.1703C98.8967 84.1375 99.5963 82.1851 100.671 80.4374C101.747 78.6897 103.174 77.185 104.862 76.0191C106.551 74.8531 108.464 74.0514 110.479 73.665C112.494 73.2786 114.568 73.3159 116.568 73.7747C118.568 74.2334 120.45 75.1035 122.096 76.3295C123.741 77.5556 125.113 79.1107 126.125 80.896L153.695 129.557C153.89 129.904 153.993 130.295 153.992 130.692C153.992 131.09 153.889 131.481 153.694 131.827L123.118 185.615C122.836 186.111 122.453 186.542 121.995 186.882C121.537 187.221 121.014 187.462 120.457 187.588C120.35 187.613 120.242 187.633 120.133 187.648Z',
    },
  }

  const Variant3 = {
    open: {
      d:
        'M93.055 28.487C90.2591 27.3103 87.0609 28.3944 84.0363 28.1638C82.2356 28.0266 80.5025 27.4183 79.0111 26.3999C77.5195 25.3816 76.322 23.989 75.5385 22.3619C74.755 20.7346 74.4132 18.9301 74.5472 17.1291C74.6812 15.3281 75.2863 13.5939 76.302 12.1006C78.651 8.64682 82.7952 6.96838 86.6965 5.47641C92.0414 3.4324 97.4396 1.37416 103.101 0.543871C108.764 -0.286657 114.791 0.202046 119.776 3.01199C125.272 6.11012 129.016 11.822 130.548 17.9426C131.917 24.1338 131.718 30.5691 129.971 36.6642C129.524 38.9265 128.531 41.045 127.078 42.8351C125.522 44.5233 122.981 45.4939 120.862 44.6119C119.03 43.8498 117.966 41.983 116.822 40.362C115.679 38.7408 114.011 37.1254 112.037 37.3206C109.495 37.572 108.24 40.5317 106.101 41.9283C103.108 43.8819 98.8444 42.2573 96.7152 39.3871C94.586 36.5169 94.0406 32.8079 93.5611 29.2666',
    },
    closed: {
      d:
        'M92.512 25.7245C90.0811 23.91 86.7161 24.1932 83.8357 23.242C82.1208 22.6758 80.5849 21.6686 79.3821 20.3214C78.1792 18.9743 77.3517 17.3346 76.9825 15.5668C76.6133 13.7989 76.7155 11.9651 77.2786 10.2492C77.8418 8.53325 78.8462 6.99554 80.1912 5.79028C83.3018 3.00272 87.728 2.37011 91.8736 1.8601C97.5532 1.1614 103.288 0.461708 108.983 1.01724C114.679 1.57277 120.412 3.49658 124.575 7.42285C129.165 11.7518 131.425 18.1963 131.44 24.5056C131.28 30.8444 129.54 37.0431 126.378 42.5391C125.4 44.6275 123.927 46.4451 122.086 47.8333C120.17 49.0978 117.47 49.4288 115.625 48.0631C114.03 46.8828 113.447 44.8151 112.726 42.9665C112.006 41.1179 110.776 39.1489 108.813 38.8637C106.285 38.4964 104.355 41.0674 101.943 41.9087C98.5683 43.0853 94.8201 40.4829 93.4436 37.185C92.0671 33.887 92.4296 30.1556 92.8158 26.6029',
    },
  }

  const Transition = {
    repeat: Infinity,
    repeatType: 'reverse',
    duration: 1,
  }

  return (
    <div className="people-container">
      <div className="people-svg">
        <svg width="50%" height="50%" viewBox="0 0 154 396" fill="none">
          <path
            d="M5.75887 141.63C7.05372 142.326 8.48546 142.73 9.95321 142.813C11.421 142.896 12.8891 142.656 14.2541 142.11C15.619 141.564 16.8477 140.726 17.8536 139.653C18.8595 138.581 19.6181 137.302 20.0759 135.905L55.8054 135.287L45.3159 119.964L12.9948 122.9C10.597 122.286 8.05735 122.573 5.8572 123.707C3.65706 124.841 1.94925 126.742 1.05734 129.051C0.165425 131.36 0.151372 133.915 1.01791 136.234C1.88444 138.552 3.57136 140.472 5.75894 141.63H5.75887Z"
            fill="#FFC6C6"
          />
          <path
            d="M27.2078 141.184C26.6541 141.184 26.1061 141.072 25.5968 140.854C25.0876 140.637 24.6277 140.319 24.2448 139.918C23.862 139.518 23.5641 139.045 23.3691 138.527C23.1741 138.008 23.0861 137.456 23.1104 136.903L23.8321 120.519C23.8714 119.543 24.2591 118.614 24.9249 117.9C25.5907 117.186 26.4905 116.734 27.461 116.626L68.8523 111.879L67.4651 139.181L27.4104 141.178C27.3428 141.182 27.2753 141.184 27.2078 141.184Z"
            fill="#90796E"
          />
          <path
            d="M77.618 365.755L65.9641 369.561L45.7371 326.421L62.9371 320.804L77.618 365.755Z"
            fill="#FFC6C6"
          />
          <path
            d="M84.2801 376.081L46.7029 388.354L46.5476 387.878C45.9202 385.958 45.6773 383.932 45.8327 381.917C45.9881 379.903 46.5388 377.939 47.4533 376.137C48.3678 374.335 49.6283 372.731 51.1628 371.417C52.6972 370.102 54.4755 369.102 56.3962 368.475L56.3971 368.475L79.3479 360.979L84.2801 376.081Z"
            fill="#2F2E41"
          />
          <path
            d="M111.944 383.796L99.6841 383.795L93.8521 336.507L111.946 336.508L111.944 383.796Z"
            fill="#FFC6C6"
          />
          <path
            d="M115.071 395.68L75.54 395.679V395.179C75.54 393.158 75.938 391.157 76.7112 389.29C77.4845 387.424 78.6179 385.728 80.0466 384.299C81.4754 382.87 83.1716 381.737 85.0384 380.963C86.9052 380.19 88.9059 379.792 90.9265 379.792H90.9276L115.071 379.793L115.071 395.68Z"
            fill="#2F2E41"
          />
          <path
            d="M93.3234 372.287C92.3211 372.285 91.354 371.917 90.6045 371.252C89.855 370.586 89.3751 369.669 89.2553 368.674L73.4484 236.529L50.3107 273.671L77.4587 343.048C77.8281 344.007 77.8261 345.07 77.4533 346.027C77.0805 346.985 76.3636 347.769 75.4431 348.226L52.4133 359.496C51.8877 359.753 51.3131 359.894 50.7281 359.911C50.1432 359.928 49.5615 359.819 49.022 359.592C48.4826 359.365 47.998 359.026 47.6008 358.596C47.2035 358.166 46.9029 357.657 46.719 357.101L21.197 279.993C19.2743 274.19 19.0231 267.963 20.4722 262.024L36.9746 194.302L37.168 194.197L74.3669 173.821L74.5798 173.899L118.388 189.888L113.803 276.706L117.134 366.814C117.18 367.885 116.802 368.93 116.083 369.724C115.363 370.518 114.36 370.998 113.29 371.058L93.5801 372.279C93.4941 372.284 93.4084 372.287 93.3234 372.287Z"
            fill="#000000"
          />
          <path
            d="M69.1918 220.036C62.6467 220.036 57.1327 218.171 53.6826 213.432L53.5712 213.28L55.8036 189.854L35.9487 200.875C35.537 201.104 35.0651 201.201 34.5967 201.152C34.1284 201.104 33.6861 200.913 33.3297 200.606C32.9733 200.298 32.7198 199.888 32.6036 199.432C32.4874 198.976 32.514 198.495 32.6799 198.054L57.4416 132.295L73.7914 78.728C74.6021 74.4406 76.696 70.5007 79.7957 67.4298C82.8955 64.359 86.8549 62.3022 91.1496 61.5317C105.715 58.8564 114.389 63.7709 124.406 74.6767C126.829 77.3302 128.613 80.5022 129.623 83.9504C130.632 87.3987 130.841 91.0321 130.232 94.5732L120.756 148.734L123.54 197.294C123.753 198.131 123.696 199.014 123.377 199.817C123.058 200.62 122.493 201.301 121.763 201.763C114.018 206.693 90.2702 220.034 69.1918 220.036Z"
            fill="#303E47"
          />
          <path
            d="M102.328 53.4209C115.893 53.4209 126.889 42.4245 126.889 28.8599C126.889 15.2952 115.893 4.29883 102.328 4.29883C88.7632 4.29883 77.7668 15.2952 77.7668 28.8599C77.7668 42.4245 88.7632 53.4209 102.328 53.4209Z"
            fill="#FFC6C6"
          />
          <motion.path
            fill="#FFC6C6"
            animate={isOpen ? 'open' : 'closed'}
            initial={false}
            variants={Variant1}
            transition={Transition}
          />
          <motion.path
            fill="#90796E"
            animate={isOpen ? 'open' : 'closed'}
            initial={false}
            variants={Variant2}
            transition={Transition}
          />
          <motion.path
            fill="#000000"
            animate={isOpen ? 'open' : 'closed'}
            initial={false}
            variants={Variant3}
            transition={Transition}
          />
        </svg>
      </div>
    </div>
  )
}

export default People
