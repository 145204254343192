import './App.css'
import FirstPage from './pages/first-page/index'
import SecondPage from './pages/second-page/index'
import ThirdPage from './pages/third-page/index'
import SixthPage from './pages/sixth-page/index'
import NavBar from './components/navbar/index'
function App() {
  return (
    <div className="App">
      <NavBar />
      <FirstPage />
      <SecondPage />
      <ThirdPage />
      <SixthPage />
    </div>
  )
}

export default App
