import React from 'react'
import './index.css'

const SixthPage = () => {
  return (
    <div className="sixth-page-container">
      <div className="title">用更聰明的方式</div>
      <div className="content">找到合適的咖啡廳</div>
      <div className="download">
        <div className="buttom">
          <img
            width="auto"
            height="100%"
            src="image/google-play.png"
            alt="google play"
          />
        </div>
        <div className="buttom">
          <img
            width="auto"
            height="100%"
            src="image/app-store.png"
            alt="app store"
          />
        </div>
      </div>
      <div className="footer">
        <div className="information-container">
          <div className="up">
            <div className="terms">服務條款</div>
            <div className="privacy">隱私權政策</div>
            <div className="phone">03-3117827</div>
          </div> 
          <div className="down">
            工作咖啡資訊科技股份有限公司 Workfe Technology Co., Ltd.
          </div>
        </div>
        <div className="blank"></div>
        <div className="media">
          <div className="img-container">
            <img
              width="100%"
              height="auto"
              src="image/instagram.png"
              alt="instagram"
            />
          </div>
          <div className="img-container">
            <img
              width="100%"
              height="auto"
              src="image/facebook.png"
              alt="facebook"
            />
          </div>
          <div className="img-container">
            <img width="100%" height="auto" src="image/mail.png" alt="mail" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SixthPage
