import { React, useState, useEffect } from 'react'
import Title from '../../components/title/index'
import Map from '../../components/map/index'
import './index.css'

const FirstPage = () => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <div className="first-page-container">
      <Title isOpen={isOpen} />
      <Map isOpen={isOpen} />
    </div>
  )
}

export default FirstPage
